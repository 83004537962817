<script>
import FormModal from "@/components/form-modal.vue";
import MasterDetail from "@/components/master-detail.vue";
import yearsOptions from "@/helpers/yearsOptions";
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  components: {
    FormModal,
    MasterDetail
  },
  computed: {
    ...mapGetters(['clientId', 'anoBase']),
    actionBarButtons() {
      return [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.createModalData = {};
            this.createModalOpened = true;
          }
        }
      ];
    },
    contextOptions() {    
      const self = this;  
      return [
        {
          name: "Editar",
          limit: 1,
          cb: (row, events) => {
            const column = self.getSelectedItem(events);            
            this.updateModalOpened = true;            
            this.updateModalData = column[0];
          },
        },
        {
          name: "Remover",
          limit: 1,
          cb: (row, events) => {
            const column = self.getSelectedItem(events);                 
            this.doDelete(column[0].id);
          },
        }
      ]
    },
    cols() {
      return this.periods.reduce(
        (acc, key) => [
          ...acc,
          {
            key,
            name: this.isQuarter
              ? key.replace("tri", " TRI")
              : moment(`${key}-01`).format("MM/yyyy"),
            type: this.$fieldTypes.MONEY
          }
        ],
        [{ key: "label", name: "" }]
      );
    },
    customResource() {
      const self = this;
      const resource = this.apiResource(`/v1/clientes/${self.clientId}/lalur`);

      return {        
        async get() {
          try {
            const query = `ano=${self.anoBase}`;
            const response = await resource.get({ query });
            self.dados = response;
            self.isQuarter = !!response.trimestral;
            self.periods = response.itens
              .reduce((acc, { competencia, trimestre }) => {
                if (self.isQuarter) {
                  const quarter = `${trimestre}tri`;
                  return acc.includes(quarter) ? acc : [...acc, quarter];
                }

                return acc.includes(competencia) ? acc : [...acc, competencia];
              }, [])
              .sort();
            const rows = response.itens.reduce(
              (acc, curr) => {
                const { competencia, trimestre, ...rest } = curr;
                const col = self.isQuarter ? `${trimestre}tri` : competencia;
                Object.entries(rest).forEach(([key, value]) => {
                  if (key in acc) {
                    acc[key][col] = value;
                  }
                });
                return acc;
              },
              {
                lucro_contabil: { label: "Lucro contábil (antes IRPJ/CSLL)" },
                total_adicoes: { label: "Total de adições" },
                total_exclusoes: { label: "Total de exclusões" },
                lucro_total: {
                  label: "Lucro após adições e exclusões"
                },
                exclusao_lei_bem: { label: "Exclusão lei do bem" },
                irpj: {
                  label: "Economia tributária — IRPJ (15% + adicional 10%)"
                },
                csll: { label: "Economia Tributária — CSLL (XXX%)" }
              }
            );

            return Promise.resolve(Object.values(rows));
          } catch (error) {
            self.isQuarter = false;
            self.periods = [];
            return Promise.resolve([]);
          }
        }
      };
    },
    fields() {
      return [
        {
          key: "lucro_contabil",
          name: "Lucro contábil (antes IRPJ/CSLL)",
          type: this.$fieldTypes.MONEY,
          colSize: 12,
          rules: [{ rule: "required" }]
        },
        {
          key: "total_adicoes",
          name: "Total de adições",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          key: "total_exclusoes",
          name: "Total exclusões",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          key: "exclusao_lei_bem",
          name: "Exclusão lei do bem",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          hideInform: this.isQuarter,
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          hideInform: !this.isQuarter,
          key: "trimestre",
          name: "Trimestre",
          type: this.$fieldTypes.SELECT,
          rel: {
            toEdit: [
              { id: 1, label: "1º trimestre" },
              { id: 2, label: "2º trimestre" },
              { id: 3, label: "3º trimestre" },
              { id: 4, label: "4º trimestre" }
            ],
            key: "id",
            name: "label"
          },
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          hideInform: !this.isQuarter,
          key: "ano",
          name: "Ano",
          type: this.$fieldTypes.SELECT,
          rel: { toEdit: yearsOptions, key: "value", name: "text" },
          colSize: 6,
          rules: [{ rule: "required" }]
        }
      ];
    }
  },
  data: function() {
    return {
      createModalData: {},
      createModalError: "",
      createModalOpened: false,
      isQuarter: false,
      periods: [],
      opts: {},
      updateModalError: "",
      updateModalOpened: false,
      updateModalData: {},
      dados: {},
    };
  },
  methods: {
    async doSave(data, closeFn) {
      const self = this;
      try {
        this.createModalError = "";
                
        await this.apiResource(`/v1/clientes/${self.clientId}/lalur`).save(data);

        if (closeFn) {
          this.$refs.masterDetail.doLoad();
          closeFn();
        }
      } catch (error) {
        this.createModalError = this.errorHandler(error);
      }
    },
    async doDelete(ids) {      
      const self = this;
      try {
        await this.apiResource(`/v1/clientes/${self.clientId}/lalur/delete`).save({id: ids});

        this.$notify({
          group: 'geral',
          type: 'success',
          title: 'Removidos',
          text: 'Dados removidos !',
          duration: 5000,
        })

        this.$refs.masterDetail.doLoad();
      } catch(error) {
        this.createModalError = this.errorHandler(error);
      }
    },
    getSelectedItem(events) {      
      return this.dados.itens.filter((item) => {
        const valueChanged = events.value.replace("tri", "");
        if(this.isQuarter && (item.trimestre == valueChanged)) {
          return item;
        } 
        if(item.competencia == valueChanged) {
          return item;
        }
      })         
    }
  }
};
</script>

<template>
  <MasterDetail
    :actionBarButtons="actionBarButtons"
    class="master-detail"
    ref="masterDetail"
    :cols="cols"
    :customResource="customResource"
    :contextOptions="contextOptions"
    disablePagination
    :opts="opts"
    has-year-filter
    :canDelete="false"
    :canEdit="false"
    :hasExportCSV="false"
    :hasNewButton="false"
  >
    <FormModal
      :cols="fields"
      :errorMessage="createModalError"
      :opened.sync="createModalOpened"
      title="Cadastro LALUR"
      :value.sync="createModalData"
      @save="doSave"
    />
    <FormModal
      :cols="fields"
      :errorMessage="updateModalError"
      :opened.sync="updateModalOpened"
      title="Edição LALUR"
      :value.sync="updateModalData"
      @save="doSave"
    />
  </MasterDetail>
</template>

<style lang="scss" scoped>
.master-detail::v-deep .v-data-table__wrapper tbody > tr {
  &:hover {
    background-color: inherit !important;
    cursor: auto;
  }

  &:nth-of-type(4) {
    background-color: #e3f2fd;

    &:hover {
      background-color: #e3f2fd !important;
    }

    & span {
      font-weight: bold;
    }
  }
}
</style>
